export const darkTheme = {
  theme: "dark",
  colors: {
    mintGreen: "#18f0ae",
    background: "hsl(206, 35%, 22%)",
    mainBackground: "hsl(210, 19%, 28%)",
    fontColor: "hsl(60, 28%, 92%)",
    subtitleColor: `hsl(332, 59%, 78%)`,
    accentColor: "hsl(202, 29%, 20%)",
    accentColorInverse: "hsl(200, 30%, 96%)",
    linkText: "hsl(332, 59%, 78%)",
    linkTextHover: "hsl(352, 89%, 88%)",
    linkUnderline: "linear-gradient(to right, #56697c 0%, #56697c 100%)",
    linkUnderlineHover: "linear-gradient(to right, #42576a 0%, #42576a 100%)",
    gradientTopRight: `linear-gradient(to right top, #42576a, #586b7e, #6e8192, #8597a7, #9dadbc)`,
    gradientBottomRight: `linear-gradient(to right bottom, #42576a, #586b7e, #6e8192, #8597a7, #9dadbc)`,
    toggleBackground: `hsl(206, 35%, 23%)`,
    toggleColor: `hsl(200, 30%, 96%)`,
    inputBorder: `#44526d`,
  },
  typography: {
    small: "0.8rem",
    paragraph: "1rem",
    h6: "1.25rem",
    h5: "1.563rem",
    h4: "1.953rem",
    h3: "2.441rem",
    h2: "3.052rem",
    h1: "3.815rem",
    titleFont: "'Helvetica', sans-serif",
    bodyFont: "'Open Sans', sans-serif",
  },
}

export const lightTheme = {
  theme: "light",
  colors: {
    mintGreen: "#18f0ae",
    background: "hsl(228,78%,98%)",
    mainBackground: "hsl(0, 0%, 100%)",
    fontColor: "hsl(225, 55%, 18%)",
    subtitleColor: `#9facd7`,
    accentColor: "hsl(225, 55%, 33%)",
    accentColorInverse: "hsl(200, 30%, 96%)",
    linkText: "#005ebf",
    linkTextHover: "#263d83",
    linkUnderline: "linear-gradient(to right, #f2f3f6 0%, #f2f3f6 100%)",
    linkUnderlineHover: "linear-gradient(to right, #18f0ae 0%, #18f0ae 100%)",
    gradientTopRight: `linear-gradient(
      45deg,
      #ff5458 0%,
      #ffc1bd 50%,
      #ffd123 100%
    )`,
    gradientBottomRight: `linear-gradient(
      to bottom right,
      #ff5458 0%,
      #ffc1bd 50%,
      #ffd123 100%
    )`,
    toggleBackground: `hsl(228,78%,90%)`,
    toggleColor: `#4666c9`,
    inputBorder: `#f2f3f6`,
  },
  typography: {
    small: "0.8rem",
    paragraph: "1rem",
    h6: "1.25rem",
    h5: "1.563rem",
    h4: "1.953rem",
    h3: "2.441rem",
    h2: "3.052rem",
    h1: "3.815rem",
    bodyFont: "'Open Sans', sans-serif",
  },
}
